import { mapGetters } from 'vuex';

export default {
  data: () => ({
    isLoading: true,
  }),

  computed: {
    ...mapGetters({
      loading: 'Organizations/getLoading',
      organization: 'Organizations/getOrganization',
      location: 'Organizations/getLocation',
      organizationLocations: 'Organizations/getOrganizationLocations',
      timezones: 'Lookups/getTimezones',
      users: 'Users/getOrganizationFormUsers',
    }),
  },

  async created() {
    try {
      await this.$store.dispatch('Lookups/fetchTimezones');
      await this.$store.dispatch('Users/fetchUsers', { page: 0, perPage: 500 });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  },

};
