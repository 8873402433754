<template>
  <div>
    <!-- loading form component -->
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    />

    <page-header name="Add New Organization">
      <template #icon>
        <b-icon
          icon="sitemap"
          type="is-primary"
        />
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-three-fifths">
            <validation-observer ref="organizationsForm">
              <!-- organizations form component -->
              <organization-form
                :users="users"
                :timezones="timezones"
                @submit-organization="createOrganization"
              />
            </validation-observer>
          </div>

          <div class="column">
            <div class="card is-primary">
              <header class="card-header">
                <p class="card-header-title">
                  locations
                </p>
              </header>

              <div class="card-content">
                <!-- organizations locations form component -->
                <organizations-locations-form
                  :loading="loading"
                  :timezones="timezones"
                  :organization-locations="organizationLocations"
                  @save-location="addLocation"
                  @remove-location="removeLocation"
                />
              </div>
            </div>
          </div>
        </div>

        <b-field>
          <b-button
            type="is-info"
            icon-left="save"
            @click="createOrganization"
          >
            create organization
          </b-button>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { PageHeader } from '@/components/Shared';
import {
  OrganizationForm,
  OrganizationsLocationsForm,
} from '@/components/Organizations';

import OrganizationsMixin from '@/mixins/OrganizationsMixin';

export default {

  name: 'AddOrganization',

  components: {
    ValidationObserver,
    PageHeader,
    OrganizationForm,
    OrganizationsLocationsForm,
  },

  mixins: [OrganizationsMixin],

  methods: {
    addLocation() {
      const index = this.organizationLocations.findIndex(
        (el) => el.name === this.location.name,
      );
      if (index >= 0) {
        this.$buefy.notification.open({
          message: 'Location already exists',
          type: 'is-warning',
        });
      } else {
        this.$store.commit('Organizations/ADD_ORGANIZATION_LOCATION', this.location);
        this.$nextTick(() => {
          this.$store.commit('Organizations/CLEAR_LOCATION');
        });
      }
    },

    removeLocation(location) {
      this.$store.commit('Organizations/REMOVE_ORGANIZATION_LOCATION', location);
    },

    async createOrganization() {
      try {
        const isValid = await this.$refs.organizationsForm.validate();
        if (!isValid) {
          // ABORT!!
        }
        await this.$store.dispatch('Organizations/createOrganization', this.organization);
        this.$buefy.notification.open({
          message: `Organization ${this.organization.name} has been created successfully`,
          type: 'is-success',
          position: 'is-bottom-right',
        });
        return this.$router.push('/organizations');
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
